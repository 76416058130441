import React from "react"


export default function More (){




    return(
        <div id="more">
            <h1> About</h1>
            <br/>
            <p> I searched up "how to make Realistic Tiles in CSS & found nothing. So I made an easy calculator to help! (in case you have the very niche problem i had)</p>

            <br/>

            <h1> About me & Socials (send me your Results)</h1>

            <br/>

            <p>  My name is Diego, im 18y/o & learning Front-End stride to be a Rust/Go Blockchain Developer </p>
            
            <br/>

            <h2> <a href="https://twitter.com/gogoDiegocrypto" target="_blank" > Twitter  </a></h2>
            <h2> <a href="https://www.youtube.com/@gogodiego" target="_blank"> Youtube </a></h2>
            <h2> <a href="https://github.com/gogoDiego" target="_blank"> Github </a></h2>
            <h2> Email: diegoscryptos@gmail.com </h2>

        </div>
    )
}